import getRequest from 'utils/request';

const GetTrialPlanBeforeStartApi = '/api/rocketAdmin/trialPlan/beforeStart';
const PostTrialPlanStartApi = '/api/rocketAdmin/trialPlan/start';
const GetTrialPlanApi = '/api/rocketAdmin/trialPlan';
const GetBeforeUpdateTrainingTypeApi =
  '/api/rocketAdmin/trialPlan/beforeUpdateTrainingType/{trialPlanId}';
const PutTrainingTypeApi = '/api/rocketAdmin/trialPlan/trainingType';
const PutComplaintApi = '/api/rocketAdmin/trialPlan/complaint';
const PostSessionFeedbackApi = '/api/rocketAdmin/trialPlan/sessionFeedback';
const PostTrialPlanDoneApi = '/api/rocketAdmin/trialPlan/done';
const GetProgramsApi = '/api/rocketAdmin/trialPlan/programs';
const GetTrialListApi = '/api/rocketAdmin/trialPlan/trialList';

export async function GetTrialList(data) {
  return getRequest()(GetTrialListApi, {
    method: 'GET',
    params: data,
  });
}

export async function PutTrainingType(data) {
  return getRequest()(PutTrainingTypeApi, {
    method: 'PUT',
    data,
  });
}

export async function PutComplaint(data) {
  return getRequest()(PutComplaintApi, {
    method: 'PUT',
    data,
  });
}

export async function PostSessionFeedback(data) {
  return getRequest()(PostSessionFeedbackApi, {
    method: 'POST',
    data,
  });
}

export async function PostTrialPlanDone(data) {
  return getRequest()(PostTrialPlanDoneApi, {
    method: 'POST',
    data,
  });
}

export async function GetTrialPlan(data) {
  return getRequest()(GetTrialPlanApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetPrograms(data) {
  return getRequest()(GetProgramsApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostPrograms(data) {
  return getRequest()(GetProgramsApi, {
    method: 'POST',
    data,
  });
}

export async function GetTrialPlanBeforeStart(data) {
  return getRequest()(GetTrialPlanBeforeStartApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetBeforeUpdateTrainingType(data) {
  return getRequest()(
    GetBeforeUpdateTrainingTypeApi.replace('{trialPlanId}', data.trialPlanId),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function PostTrialPlanStart(data) {
  return getRequest()(PostTrialPlanStartApi, {
    method: 'POST',
    data,
  });
}
