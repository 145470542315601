import moment from 'moment'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { message, Modal, Row, notification, Space, Button } from 'antd'
import { isJson } from 'cognitiveleap-core-us/utils/helper'
import { showGenderColor, getShowName } from 'cognitiveleap-core-us/utils/utils'
import storageKeys from './storageKeys'
import localForage from 'localforage'
import { GetunReadCount } from 'services/notification'
import {
  GetFeedbackVersion,
  GetGroupFeedbackVersion,
} from 'services/rocketService/UserOfflinePlan';
import { notificationIntervalTime, pcUrl } from 'utils/config'
import { videoSeparator } from 'cognitiveleap-core-us/utils/config'
import html2canvas from 'html2canvas'
import { getDvaApp, history, useIntl } from 'umi'
import userStaff from 'assets/subject_default.png';
import CryptoJS from 'crypto-js';

let wakeLock = null;

export async function requestWakeLock() {
  try {
      wakeLock = await navigator.wakeLock.request('screen');
      console.log('Wake Lock is active');
  } catch (err) {
      console.error(`${err.name}, ${err.message}`);
  }
}

export async function releaseWakeLock() {
  if (wakeLock) {
      await wakeLock.release();
      wakeLock = null;
      console.log('Wake Lock is released');
  }
}

export async function getVersionReportUrl(ilsVersion, id, num, from) {
  const res =
    from == 'group'
      ? await GetGroupFeedbackVersion({ groupTrainingPlanCourseId: id, num })
      : await GetFeedbackVersion({ userOfflinePlanId: id, num });

  if (res.response.ok) {
    const { data } = res || {};

    if (data == 1) {
      return '/hfsThreeTrainingRecord';
    } else {
      if (ilsVersion === 'V4') {
        return '/hfsTwoTrainingRecord';
      } else {
        return '/hfsTrainingRecord';
      }
    }
  }
}

export function getEchartWeekAssigned(startTime, stopTime, week) {
  const tempWeek = stopTime ? moment(stopTime).diff(moment(startTime).day(0), 'weeks', true) : moment().diff(moment(startTime).day(0), 'weeks', true);
  const weekAssigned = Math.ceil(tempWeek);

  const currentWeek = week || weekAssigned;

  const startDay = moment(startTime).add((currentWeek - 1), 'weeks').day(0);
  const endDay = moment(startTime).add((currentWeek - 1), 'weeks').day(6);

  return {
    weekAssigned,
    startDay,
    endDay,
    currentWeek
  };
}

export function getGroupTag(type, intl) {
  if (type === 'OneToOne') {
    return `${intl.formatMessage({ id: 'OneToOne' })}`;
  } else if (type === 'OneToTwo') {
    return `${intl.formatMessage({ id: 'OneToTwo' })}`;
  } else if (type === 'OneToThree') {
    return `${intl.formatMessage({ id: 'OneToThree' })}`;
  } else if (type === 'OneToFour') {
    return `${intl.formatMessage({ id: 'OneToFour' })}`;
  } else if (type === 'OneToFive') {
    return `${intl.formatMessage({ id: 'OneToFive' })}`;
  } else if (type === 'OneToSix') {
    return `${intl.formatMessage({ id: 'OneToSix' })}`;
  } else if (type === 'Class') {
    return `${intl.formatMessage({ id: 'class' })}`;
  } else {
    return `${intl.formatMessage({ id: 'OneToMany' })}`;
  }
}

// 是否是需要提醒的课时 10*N-1~10*N+2 N >= 1
export function isParentalInterviewsNeed(num, type = 'end') {
  if (num < 9) return false

  let times;
  if (num % 10 < 5) {
    times = Math.floor(num / 10)
  } else {
    times = Math.ceil(num / 10)
  }

  if (type === 'end') {
    if (num >= (10 * times - 1) && num <= (10 * times + 2)) return true
  } else {
    // 本阶段最后开启下一课时提示  10*N+2 -> 10*N+3 时
    if (num === (10 * times + 2)) return true
  }

  return false
}

// 是否是需要提醒的课时 40*N-2~40*N+1 N >= 1
export function isShowFASTip(num) {
  const temp = 40;

  let isSameN = 'same';
  let N;

  if (num % temp < temp / 2) {
    N = Math.floor(num / temp)
  } else {
    N = Math.ceil(num / temp)
  }

  const lowerBound = temp * N - 2;
  const upperBound = temp * N + 1;

  if (num == upperBound) {
    isSameN = 'last';
  } else if (num < temp * N) {
    isSameN = 'before';
  }

  return {
    needTip: num >= lowerBound && num <= upperBound,
    isSameN,
    N
  };
}

// 仅用于下载课程反馈表使用，axios尝试不能成功，暂时使用一个估计值作为下载剩余时间
// 根据下载数量推算出一个大概时间
// 下载1个需要6秒，2个需要7秒，3个需要9秒
// 下载4个需要13秒，5个需要14秒，6个需要15秒
// 下载7个需要18秒，目前三个关下页面再打开一个页面需要4秒时间
export function getDurationTime(num) {
  const closeNum = parseInt(num / 3);
  const time = 6 + (closeNum * 4) + (num - closeNum - 1);
  return time;
}

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;
    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  const year = now.getFullYear();

  if (type === 'month') {
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();
    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

function toCase(json) {
  if (typeof json === 'object') {
    if (Array.isArray(json)) {
      json.forEach(function (item) {
        toCase(item)
      })
    } else {
      for (let key in json) {
        let item = json[key]
        if (typeof item === 'object') {
          toCase(item)
        }
        if (/^[A-Z]/.test(key.charAt(0))) {
          json[key.substring(0, 1).toLowerCase() + key.substring(1)] = json[key]
          delete json[key]
        }
      }
    }
  }
}

export function getRandomName() {
  var firstNames = new Array(
    '赵', '钱', '孙', '李', '周', '吴', '郑', '王', '冯', '陈', '楮', '卫', '蒋', '沈', '韩', '杨',
    '朱', '秦', '尤', '许', '何', '吕', '施', '张', '孔', '曹', '严', '华', '金', '魏', '陶', '姜',
    '戚', '谢', '邹', '喻', '柏', '水', '窦', '章', '云', '苏', '潘', '葛', '奚', '范', '彭', '郎',
    '鲁', '韦', '昌', '马', '苗', '凤', '花', '方', '俞', '任', '袁', '柳', '酆', '鲍', '史', '唐',
    '费', '廉', '岑', '薛', '雷', '贺', '倪', '汤', '滕', '殷', '罗', '毕', '郝', '邬', '安', '常',
    '乐', '于', '时', '傅', '皮', '卞', '齐', '康', '伍', '余', '元', '卜', '顾', '孟', '平', '黄',
    '和', '穆', '萧', '尹', '姚', '邵', '湛', '汪', '祁', '毛', '禹', '狄', '米', '贝', '明', '臧',
    '计', '伏', '成', '戴', '谈', '宋', '茅', '庞', '熊', '纪', '舒', '屈', '项', '祝', '董', '梁',
    '杜', '阮', '蓝', '闽', '席', '季', '麻', '强', '贾', '路', '娄', '危', '江', '童', '颜', '郭',
    '梅', '盛', '林', '刁', '锺', '徐', '丘', '骆', '高', '夏', '蔡', '田', '樊', '胡', '凌', '霍',
    '虞', '万', '支', '柯', '昝', '管', '卢', '莫', '经', '房', '裘', '缪', '干', '解', '应', '宗',
    '丁', '宣', '贲', '邓', '郁', '单', '杭', '洪', '包', '诸', '左', '石', '崔', '吉', '钮', '龚',
    '程', '嵇', '邢', '滑', '裴', '陆', '荣', '翁', '荀', '羊', '於', '惠', '甄', '麹', '家', '封',
    '芮', '羿', '储', '靳', '汲', '邴', '糜', '松', '井', '段', '富', '巫', '乌', '焦', '巴', '弓',
    '牧', '隗', '山', '谷', '车', '侯', '宓', '蓬', '全', '郗', '班', '仰', '秋', '仲', '伊', '宫',
    '宁', '仇', '栾', '暴', '甘', '斜', '厉', '戎', '祖', '武', '符', '刘', '景', '詹', '束', '龙',
    '叶', '幸', '司', '韶', '郜', '黎', '蓟', '薄', '印', '宿', '白', '怀', '蒲', '邰', '从', '鄂',
    '索', '咸', '籍', '赖', '卓', '蔺', '屠', '蒙', '池', '乔', '阴', '郁', '胥', '能', '苍', '双',
    '闻', '莘', '党', '翟', '谭', '贡', '劳', '逄', '姬', '申', '扶', '堵', '冉', '宰', '郦', '雍',
    '郤', '璩', '桑', '桂', '濮', '牛', '寿', '通', '边', '扈', '燕', '冀', '郏', '浦', '尚', '农',
    '温', '别', '庄', '晏', '柴', '瞿', '阎', '充', '慕', '连', '茹', '习', '宦', '艾', '鱼', '容',
    '向', '古', '易', '慎', '戈', '廖', '庾', '终', '暨', '居', '衡', '步', '都', '耿', '满', '弘',
    '匡', '国', '文', '寇', '广', '禄', '阙', '东', '欧', '殳', '沃', '利', '蔚', '越', '夔', '隆',
    '师', '巩', '厍', '聂', '晁', '勾', '敖', '融', '冷', '訾', '辛', '阚', '那', '简', '饶', '空',
    '曾', '毋', '沙', '乜', '养', '鞠', '须', '丰', '巢', '关', '蒯', '相', '查', '后', '荆', '红',
    '游', '竺', '权', '逑', '盖', '益', '桓', '公', '仉', '督', '晋', '楚', '阎', '法', '汝', '鄢',
    '涂', '钦', '岳', '帅', '缑', '亢', '况', '后', '有', '琴', '归', '海', '墨', '哈', '谯', '笪',
    '年', '爱', '阳', '佟', '商', '牟', '佘', '佴', '伯', '赏', "万俟", "司马", "上官", "欧阳", "夏侯",
    "诸葛", "闻人", "东方", "赫连", "皇甫", "尉迟", "公羊", "澹台", "公冶", "宗政", "濮阳", "淳于",
    "单于", "太叔", "申屠", "公孙", "仲孙", "轩辕", "令狐", "锺离", "宇文", "长孙", "慕容", "鲜于",
    "闾丘", "司徒", "司空", "丌官", "司寇", "子车", "微生", "颛孙", "端木", "巫马", "公西", "漆雕",
    "乐正", "壤驷", "公良", "拓拔", "夹谷", "宰父", "谷梁", "段干", "百里", "东郭", "南门", "呼延",
    "羊舌", "梁丘", "左丘", "东门", "西门", "南宫"
  );

  var lastNames = new Array(
    '子璇', '淼', '国栋', '夫子', '瑞堂', '甜', '敏', '尚', '国贤', '贺祥', '晨涛',
    '昊轩', '易轩', '益辰', '益帆', '益冉', '瑾春', '瑾昆', '春齐', '杨', '文昊',
    '东东', '雄霖', '浩晨', '熙涵', '溶溶', '冰枫', '欣欣', '宜豪', '欣慧', '建政',
    '美欣', '淑慧', '文轩', '文杰', '欣源', '忠林', '榕润', '欣汝', '慧嘉', '新建',
    '建林', '亦菲', '林', '冰洁', '佳欣', '涵涵', '禹辰', '淳美', '泽惠', '伟洋',
    '涵越', '润丽', '翔', '淑华', '晶莹', '凌晶', '苒溪', '雨涵', '嘉怡', '佳毅',
    '子辰', '佳琪', '紫轩', '瑞辰', '昕蕊', '萌', '明远', '欣宜', '泽远', '欣怡',
    '佳怡', '佳惠', '晨茜', '晨璐', '运昊', '汝鑫', '淑君', '晶滢', '润莎', '榕汕',
    '佳钰', '佳玉', '晓庆', '一鸣', '语晨', '添池', '添昊', '雨泽', '雅晗', '雅涵',
    '清妍', '诗悦', '嘉乐', '晨涵', '天赫', '玥傲', '佳昊', '天昊', '萌萌', '若萌',
    "秋白", "南风", "醉山", "初彤", "凝海", "紫文", "凌晴", "香卉", "雅琴", "傲安",
    "傲之", "初蝶", "寻桃", "代芹", "诗霜", "春柏", "绿夏", "碧灵", "诗柳", "夏柳",
    "采白", "慕梅", "乐安", "冬菱", "紫安", "宛凝", "雨雪", "易真", "安荷", "静竹",
    "飞雪", "雪兰", "雅霜", "从蓉", "冷雪", "靖巧", "翠丝", "觅翠", "凡白", "乐蓉",
    "迎波", "丹烟", "梦旋", "书双", "念桃", "夜天", "海桃", "青香", "恨风", "安筠",
    "觅柔", "初南", "秋蝶", "千易", "安露", "诗蕊", "山雁", "友菱", "香露", "晓兰",
    "涵瑶", "秋柔", "思菱", "醉柳", "以寒", "迎夏", "向雪", "香莲", "以丹", "依凝",
    "如柏", "雁菱", "凝竹", "宛白", "初柔", "南蕾", "书萱", "梦槐", "香芹", "南琴",
    "绿海", "沛儿", "晓瑶", "听春", "易巧", "念云", "晓灵", "静枫", "夏蓉", "如南",
    "幼丝", "秋白", "冰安", "凝蝶", "紫雪", "念双", "念真", "曼寒", "凡霜", "白卉",
    "语山", "冷珍", "秋翠", "夏柳", "如之", "忆南", "书易", "翠桃", "寄瑶", "如曼",
    "问柳", "香梅", "幻桃", "又菡", "春绿", "醉蝶", "亦绿", "诗珊", "听芹", "新之",
    "博瀚", "博超", "才哲", "才俊", "成和", "成弘", "昊苍", "昊昊", "昊空", "昊乾",
    "昊然", "昊然", "昊天", "昊焱", "昊英", "浩波", "浩博", "浩初", "浩大", "浩宕",
    "浩荡", "浩歌", "浩广", "浩涆", "浩瀚", "浩浩", "浩慨", "浩旷", "浩阔", "浩漫",
    "浩淼", "浩渺", "浩邈", "浩气", "浩然", "浩穰", "浩壤", "浩思", "浩言", "皓轩",
    "和蔼", "和安", "和昶", "翔东", "昊伟", "楚桥", "智霖", "浩杰", "炎承", "思哲",
    "璟新", "楚怀", "继智", "昭旺", "俊泽", "子中", "羽睿", "嘉雷", "鸿翔", "明轩",
    "棋齐", "轶乐", "昭易", "臻翔", "泽鑫", "芮军", "浩奕", "宏明", "忠贤", "锦辉",
    "元毅", "霈胜", "宇峻", "子博", "语霖", "胜佑", "俊涛", "浩淇", "乐航", "泽楷",
    "嘉宁", "敬宣", "韦宁", "建新", "宇怀", "皓玄", "冠捷", "俊铭", "一鸣", "堂耀",
    "轩凝", "舰曦", "跃鑫", "梓杰", "筱宇", "弘涛", "羿天", "广嘉", "陆铭", "志卿",
    "连彬", "景智", "孟昕", "羿然", "文渊", "羿楦", "晗昱", "晗日", "涵畅", "涵涤",
    "昊穹", "涵亮", "涵忍", "涵容", "俊可", "智鹏", "诚钰", "书墨", "俊易", "浩渺",
    "宸水", "嘉许", "时贤", "飞腾", "沂晨", "殿斌", "霄鸿", "辰略", "澜鸿", "景博",
    "咨涵", "修德", "景辉", "语旋", "智逸", "鸿锋", "思梵", "弈煊", "泰河", "逞宇",
    "嘉颢", "锦沅", "颢焱", "萧彬", "悦升", "香音", "烨柠", "颢咏", "仁贤", "尚然",
    "羿鳞", "月鸿", "健霖", "鸿昊", "竣杰", "可顺", "炯乐", "俊彦", "海沧", "捷明",
    "飞扬", "杰辰", "羽捷", "曦晴", "裕鸿", "翌锦", "沐宸", "福同", "旻驰", "龙宁",
    "文虹", "义凡", "广晨", "宸滔", "嘉岐", "雅珺", "睿明", "皓轩", "程天", "子酝",
    "艾康", "如羽", "冠玉", "子歉", "永昊", "龙华", "兆颜", "奇文", "月昕", "裕锦",
    "昂佳", "昊浩", "宇韬", "睿焓", "永译", "鸿彬", "颢霖", "益彬", "虹昊", "飞悦",
    "睿珏", "?宵童", "睿鸿", "容冰", "逸濠", "楷岩", "弘义", "海萦", "昊孺", "梓铭",
    "生钊", "蓝玺", "晨辕", "宇菡", "砚海", "文揩", "韬瑞", "彦红", "奕韦", "清予",
    "宁翼", "冬睿", "锦昌", "烨宁", "昌权", "国研", "德运", "孝清", "佳阳", "凯玮",
    "正真", "民云", "昕冶", "力威", "帅欣", "知淳", "烨飞", "兴远", "子墨", "澄欣",
    "烨煊", "悦勤", "晨津", "博宏", "育萌", "羽炫", "绍钧", "睿昌", "泓千", "颢炜",
    "虹金", "筠航", "元甲", "星明", "景涛", "铭虹", "德本", "向辉", "基翔", "家易",
    "欣鹏", "羽荃", "泽容", "弘亮", "尚廷", "轩梓", "甫津", "彬楷", "寅飞", "愉君",
    "阳平", "誉杰", "钦昭", "蕴藉", "羽程", "宏海", "涵畅", "光浩", "令沂", "浩浩",
    "睿锦", "易泽", "俊康", "家文", "晨元", "语洋", "裕宏", "梓榛", "阳嘉", "恒展",
    "雨远", "哲伊", "逸江", "丰源", "学东", "奇岩", "浩财", "和蔼", "红言", "瑞赫",
    "森圆", "欣赢", "梓鸿", "博明", "铭育", "颢硕", "宇烯", "宇如", "淳炎", "源承",
    "斌彬", "飞沉", "鸿璐", "昊弘", "正阳"
  );

  var firstLength = firstNames.length;
  var lastLength = lastNames.length;

  var i = parseInt(Math.random() * firstLength);
  var j = parseInt(Math.random() * lastLength);
  var name = '*' + firstNames[i] + lastNames[j] + '*';

  return name;
}


// 数字位数补零统一
export function PrefixZero(num, n) {
  return (Array(n).join(0) + num).slice(-n);
}



function jsonKeysToLowerCase(json) {
  if (typeof json === 'object') {
    toCase(json)
    return json
  } else {
    return json
  }
}

export function parseRes(res) {
  const { data, response } = res
  return { data: jsonKeysToLowerCase(data), response }
}

export function genderLocale(gender, intl) {
  switch (gender) {
    case 'Unknown':
      return intl.formatMessage({ id: 'unknown' })
    case 'Male':
      return intl.formatMessage({ id: 'male' })
    case 'Female':
      return intl.formatMessage({ id: 'female' })
    case 'Others':
      return intl.formatMessage({ id: 'other' })
    default:
      return intl.formatMessage({ id: 'other' })
  }
}

export function handLocale(hand, intl) {
  switch (hand) {
    case 'LeftHand':
      return intl.formatMessage({ id: 'left' })
    case 'Right':
      return intl.formatMessage({ id: 'right' })
    default:
      return ''
  }
}

export function getSeconds(value) {
  const tempValue = value.split(':');
  const hour = parseInt(tempValue[0])
  const minute = parseInt(tempValue[1])
  const second = parseInt(tempValue[2])
  return parseInt(hour * 60 * 60 + minute * 60 + second)
}

export function checkFeedbackProperty(type, extra, extraBaseObj = {}) {
  if (!isJson(extra)) {
    message.error(`${type}类型的Extra不是Json`)
    return false
  }
  else {
    let isTypeExtraOk = true
    const extraObj = { ...extraBaseObj, ...JSON.parse(extra) }
    switch (type) {
      //options:[{value,text}]
      case 'Selection':
        isTypeExtraOk = extraObj.hasOwnProperty('options')
        if (isTypeExtraOk) {
          isTypeExtraOk = Array.isArray(extraObj.options)
          if (isTypeExtraOk) {
            isTypeExtraOk = extraObj.options.every(item => {
              return item.hasOwnProperty('value') && item.hasOwnProperty('text')
            })
          }
        }
        if (!isTypeExtraOk) {
          message.error(`selection的options不符合格式要求`)
          return false
        }
        isTypeExtraOk = extraObj.hasOwnProperty('zh') || extraObj.hasOwnProperty('en')
        if (isTypeExtraOk) {
          isTypeExtraOk = Array.isArray(extraObj['zh']?.options) && Array.isArray(extraObj['en']?.options)
          if (isTypeExtraOk) {
            isTypeExtraOk = extraObj['zh']?.options.every(item => {
              return item.hasOwnProperty('value') && item.hasOwnProperty('text')
            }) && extraObj['en']?.options.every(item => {
              return item.hasOwnProperty('value') && item.hasOwnProperty('text')
            })
          }
          if (!isTypeExtraOk) {
            message.error(`selection的多语言options不符合格式要求`)
            return false
          }
        }
        break
      //count
      case 'Rating':
        isTypeExtraOk = extraObj.hasOwnProperty('count')
        if (!isTypeExtraOk) {
          message.error(`rating应该包含count属性`)
          return false
        }
        isTypeExtraOk = extraObj.hasOwnProperty('zh') || extraObj.hasOwnProperty('en')
        if (isTypeExtraOk) {
          isTypeExtraOk = extraObj['zh']?.hasOwnProperty('count') && extraObj['en']?.hasOwnProperty('count')

          if (!isTypeExtraOk) {
            message.error(`rating多语言应该包含count属性`)
            return false
          }
        }
        break
      case 'String':
      case 'Duration':
      case 'Boolean':
      case 'Number':
      case 'TimePicker':
      case 'ImagePicker':
      case 'VideoPicker':
    }

    return true
  }
}

export const propertyTypes = [{
  Value: 'String', Id: 'String'
}, {
  Value: 'Rating', Id: 'Rating'
}, {
  Value: 'Boolean', Id: 'Boolean'
}, {
  Value: 'Duration', Id: 'Duration'
}, {
  Value: 'Number', Id: 'Number'
}, {
  Value: 'Selection', Id: 'Selection'
}, {
  Value: 'TimePicker', Id: 'TimePicker'
}, {
  Value: 'ImagePicker', Id: 'ImagePicker'
}, {
  Value: 'VideoPicker', Id: 'VideoPicker'
}, {
  Value: 'Checkbox', Id: 'Checkbox'
}]

export function showPropertyDescription() {
  // Type: String AvailableKeys: maxLength; minLength;
  //  Type: Number AvailableKeys: maxValue; minValue;
  //   Type: Boolean AvailableKeys: 
  //   Type: Duration AvailableKeys: maxValue; minValue; 
  //   Type: Rating AvailableKeys: maxValue; minValue; step;
}

export function countAge(birthDay, intl, testDate = moment()) {
  if (birthDay == null) {
    return ''
  }

  let diffYear = 0
  let diffMonth = 0

  diffYear = moment(moment(testDate).format('YYYY-MM-DD')).diff(moment(birthDay), 'years');
  diffMonth = moment(moment(testDate).format('YYYY-MM-DD')).diff(moment(birthDay), 'months');

  if (diffMonth >= 12) {
    diffMonth = diffMonth - diffYear * 12;
  }

  let str = ''
  if (diffYear > 0) {
    str = diffYear.toString() + intl.formatMessage({ id: 'y' })
  }
  if (diffMonth > 0) {
    str = str + ' ' + diffMonth.toString() + intl.formatMessage({ id: 'm' })
  }
  return str
}

export function showGender(gender, intl) {
  switch (gender) {
    case 'Unknown':
      return <span>{intl.formatMessage({ id: 'unknown' })}</span>
    case 'Male':
      return <span>{intl.formatMessage({ id: 'male' })}</span>
    case 'Female':
      return <span>{intl.formatMessage({ id: 'female' })}</span>
    case 'Others':
      return <span>{intl.formatMessage({ id: 'other' })}</span>
    default:
      return <span></span>
  }
}

export function showDominantHand(dominantHand, intl) {
  switch (dominantHand) {
    case 'Unknown':
      return <span>{intl.formatMessage({ id: 'unknown' })}</span>
    case 'LeftHand':
      return <span>{intl.formatMessage({ id: 'left' })}</span>
    case 'Right':
      return <span>{intl.formatMessage({ id: 'right' })}</span>
    default:
      return <span></span>
  }
}

export function convertDuration(time) {
  if (!time) {
    return '00:00:00'
  }
  let result = parseInt(time)
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
  return `${h}:${m}:${s}`;
}

export function getActiveContentTime(timeConsuming, intl) {
  if (!timeConsuming) return intl.formatMessage({ id: 'whichHours' }, { num: 0 })
  timeConsuming = convertDuration(timeConsuming * 60)
  timeConsuming = timeConsuming.split(':')
  const studyHour = timeConsuming[0]
  const studyMin = timeConsuming[1]
  const hour = parseInt(studyHour) ? intl.formatMessage({ id: 'whichHours' }, { num: parseInt(studyHour) }) : ''
  const min = intl.formatMessage({ id: 'whichMins' }, { num: parseInt(studyMin) })
  if (parseInt(studyHour) > 0 && parseInt(studyMin) === 0) {
    return hour
  }
  if (parseInt(studyHour) === 0 && parseInt(studyMin) === 0) {
    return intl.formatMessage({ id: 'whichMins' }, { num: 0 })
  }
  return hour + ' ' + min
}

export async function getCurrentSubject(newVal) {
  if (newVal === undefined) {
    return await localForage.getItem(storageKeys.currentSubject).catch(err => {
      console.log('get subject error')
      console.log(err)
      return null
    })
  } else {
    return await localForage.setItem(storageKeys.currentSubject, newVal).catch(err => {
      console.log('set subject error')
      console.log(err)
      return null
    })
  }
}

// 验证日期区域
export function isLegitimateDate(preDate, nextDate) {
  if (!preDate.endTime || !nextDate.endTime) {
    return true
  } else {
    return moment(preDate.endTime).isSameOrBefore(nextDate.endTime);
  }
}

// 下载中心二维码
export function downloadQr({ type, data, title, subTitle, description = '', descriptionSecend = '', downloadName, imgType }) {

  let url = '';
  if (type == 'blob') {
    const blob = new Blob([data]);
    url = (window.URL || window.webkitURL).createObjectURL(blob);
  } else if (type == 'base64') {
    url = data.includes("data:image/png;base64,") ? data : `data:image/png;base64,${data}`
  }

  // 创建画布
  const body = document.body

  const canvas = document.createElement('div');

  // 创建一个canvas区域
  canvas.id = 'canvas';
  canvas.style = 'width: 375px; padding: 50px 20px 20px 20px; position: absolute; top: 0; left: -10000px;';

  let titleDom = null
  let subTitleDom = null
  // 显示标题与副标题
  switch (imgType) {
    case "centerCode":
    case "questionnaire":
    case "examCode":
    case "assessmentCode":
      // 显示标题
      titleDom = document.createElement('p');
      titleDom.style = 'font-weight: 600;font-size: 30px;text-align: center';
      titleDom.innerText = title;
      canvas.appendChild(titleDom)

      // 显示副标题
      subTitleDom = document.createElement('p');
      subTitleDom.style = 'font-size: 24px;text-align: center'
      subTitleDom.innerText = subTitle
      canvas.appendChild(subTitleDom)
      break
    case "trainingCode":
      // 显示标题
      titleDom = document.createElement('p');
      titleDom.style = 'font-size: 20px;text-align: center';
      titleDom.innerText = title;
      canvas.appendChild(titleDom)

      // 显示副标题
      subTitleDom = document.createElement('p');
      subTitleDom.style = 'font-size: 24px;text-align: center'
      subTitleDom.innerHTML = `
        <div>
          申请 <span style="font-weight: 600; font-size: 26px;text-align: center">HFS优脑训练师</span> 认证培训
        </div>
      `
      canvas.appendChild(subTitleDom)
      break
    case "feedbackCode":
      // 显示标题
      titleDom = document.createElement('p');
      titleDom.style = 'font-size: 20px;text-align: center';
      titleDom.innerText = title;
      canvas.appendChild(titleDom)

      // 显示副标题
      subTitleDom = document.createElement('p');
      subTitleDom.style = 'font-size: 24px;text-align: center'
      subTitleDom.innerHTML = `
        <div>
          <span style="font-weight: 600; font-size: 26px;text-align: center">填写培训反馈</span>
        </div>
      `
      canvas.appendChild(subTitleDom)
      break
  }

  // 显示图片
  const imgDom = document.createElement('img');
  imgDom.style = 'margin:10px 0 10px 12px;width: 311px; height: 311px';
  imgDom.src = url;
  canvas.appendChild(imgDom)

  // 显示描述
  if (description !== '') {
    const descriptionDom = document.createElement('p');
    descriptionDom.style = 'font-size: 14px;text-align: center'
    descriptionDom.innerText = description
    canvas.appendChild(descriptionDom)
  }

  if (descriptionSecend !== '') {
    // 显示第二行描述
    const descriptionSecendDom = document.createElement('p');
    descriptionSecendDom.style = 'font-size: 14px;text-align: center';
    descriptionSecendDom.innerHTML = descriptionSecend
    canvas.appendChild(descriptionSecendDom);
  }

  body.appendChild(canvas)

  window.scrollTo(0, 0)

  html2canvas(canvas, {
    dpi: window.devicePixelRatio * 3,
    scale: 3,
    scrolly: 0,
    scrollx: 0,
    useCORS: true
  }).then(data => {
    const url = data.toDataURL('image/png')
    const oA = document.createElement("a");
    oA.download = downloadName;
    oA.href = url
    body.appendChild(oA)
    oA.click();
    oA.remove();
    canvas.remove()
  })
}

export function getFormatShowText(intl, keyList) {
  if (intl && keyList) {
    const formatList = keyList.map(item => {
      return {
        key: item,
        value: intl.formatMessage({ id: item })
      }
    })
    return formatList.reduce((target, item) => {
      return { ...target, [item.key]: item.value }
    }, {})
  }
  else return {}
}

export function getAdminName(type) {
  const intl = useIntl()
  switch (type) {
    case "editor":
    case "editorAdmin":
      return "内容"
    case "operationAdmin":
    case "operation":
      return "运营"
    case "finance":
    case "financeAdmin":
      return "财务"
    case "medicalSupport":
      return "医学支持"
    case "sales":
    case "salesAdmin":
      return "销售"
    case "training":
      return "培训"
    case "agent":
      return intl.formatMessage({ id: 'agent' })
    default:
      return type
  }
}

// 统一时间筛选
export function getFormatDate(date, isStart) {
  return moment(moment(date).format('YYYY-MM-DD')).add(1, 'd').add(-1, isStart ? 'd' : 'seconds').format()
}

export function getActiveWeekdays(weekAssigned) {
  return Array(parseInt(weekAssigned || 0)).fill(0).map((_, index) => {
    return {
      label: index + 1,
      key: index + 1,
    }
  }).splice(0, weekAssigned);
}

const countTypeToField = {
  User: 'unreadUserCount',
  System: 'unreadSystemCount'
}

const getReadCount = (type) => {
  GetunReadCount({ Type: type }).then(res => {
    const payload = {
      [countTypeToField[type]]: res.data
    }
    getDvaApp()._store.dispatch({
      type: 'notification/updateState',
      payload,
    })
  })
}

const event = () => {
  getReadCount('User')
  getReadCount('System')
}

// 轮询获取通知消息数量
export function getNotificationCount() {
  // if (!window.getNotificationTimer) {
  //   event()
  //   window.getNotificationTimer = setInterval(() => {
  //     event()
  //   }, notificationIntervalTime * 1000)
  // } else {
  //   event()
  // }
}

// 统一通知消息数据结构
export function formatNotificationData(data) {
  let newData = {};
  if (!data) {
    return null
  }

  data.forEach(item => {
    let isPushed = false;

    if (moment().isSame(item.creationTime, 'd')) {
      isPushed = true;
      !newData.todayData && (newData.todayData = []);
      newData.todayData.push(item)
    }

    if (moment().add(-1, 'days').isSame(item.creationTime, 'd')) {
      isPushed = true;
      !newData.yesterdayData && (newData.yesterdayData = []);
      newData.yesterdayData.push(item)
    }

    if (!isPushed) {
      !newData.otherdayData && (newData.otherdayData = []);
      newData.otherdayData.push(item)
    }
  })

  return newData;
}

// 本地保存音乐进度
export async function localSaveMusicRecord({ userOfflinePlanId, num, trackCode, time }) {
  let musicRecord = await localForage.getItem(storageKeys.musicRecord)
  if (musicRecord) {
    musicRecord[userOfflinePlanId] = { num, trackCode, time }
  } else {
    musicRecord = { [userOfflinePlanId]: { num, trackCode, time } }
  }
  await localForage.setItem(storageKeys.musicRecord, musicRecord)
}

export function countDown(showImage, Code, intl) {
  let secondsToGo = 40
  const modal = Modal.success({
    title: intl.formatMessage({ id: 'testQRCode' }),
    content: (
      <div>
        <br />
        <Row type="flex" justify="center">
          <img alt="QRCode" src={showImage} width={100} height={100} />
        </Row>
        <Row type="flex" justify="center">
          <div>{Code}</div>
        </Row>
        {/* <div>{`${useIntl().formatMessage({id: 'modalWillBeDestroyed'})} ${secondsToGo} ${useIntl().formatMessage({id: 'second'})}.`}</div> */}
      </div>
    ),
  })
  const timer = setInterval(() => {
    secondsToGo -= 1
    modal.update({
      content: (
        <div>
          <br />
          <Row type="flex" justify="center">
            <img alt="QRCode" src={showImage} width={100} height={100} />
          </Row>
          <Row type="flex" justify="center">
            <div>{Code}</div>
          </Row>
          {/* <div>{`${useIntl().formatMessage({id: 'modalWillBeDestroyed'})} ${secondsToGo} ${useIntl().formatMessage({id: 'second'})}.`}</div> */}
        </div>
      ),
    })
  }, 1000)
  setTimeout(() => {
    clearInterval(timer)
    modal.destroy()
  }, secondsToGo * 1000)
}

// 根据moment返回的值获取周几
export function getWeekday(week, intl) {
  let day = '';
  switch (week) {
    case 0:
      day = intl.formatMessage({ id: 'Sunday' });
      break;
    case 1:
      day = intl.formatMessage({ id: 'Monday' });
      break;
    case 2:
      day = intl.formatMessage({ id: 'Tuesday' });
      break;
    case 3:
      day = intl.formatMessage({ id: 'Wednesday' });
      break;
    case 4:
      day = intl.formatMessage({ id: 'Thursday' });
      break;
    case 5:
      day = intl.formatMessage({ id: 'Friday' });
      break;
    case 6:
      day = intl.formatMessage({ id: 'Saturday' });
  }
  return day;
}

export function getNextKey({ id, planitems, nonePlayBooks, rocketProjectStatus, isBind = true, isLogout = false, hasOfflinePlanWritePermission }) {
  const isLegitimatevalue = (hasDone) => {
    return getDisabledValue({ hasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) === false && getHasDoneValue({ hasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })
  }

  if (id) {
    if (planitems) {
      const planitemIndex = planitems.findIndex(item => item.id === id)
      if (planitemIndex !== -1 && planitemIndex !== planitems.length - 1) {
        //找到后续第一个未做的
        const nextItem = planitems.slice(planitemIndex + 1, planitems.length).find(item => isLegitimatevalue(item.hasDone))
        if (nextItem) return nextItem.id
        //如果后续都完成了，那就去noneplaybook里去找第一个未做的
        else {
          if (nonePlayBooks && nonePlayBooks.length > 0) {
            const nextItem = nonePlayBooks.find(item => isLegitimatevalue(item.hasDone))
            if (nextItem) return nextItem.id
          }
        }
      }
      if (nonePlayBooks) {
        const planitemIndex = nonePlayBooks.findIndex(item => item.id === id)
        if (planitemIndex !== -1 && planitemIndex !== nonePlayBooks.length - 1) {
          const nextItem = nonePlayBooks.slice(planitemIndex + 1, nonePlayBooks.length).find(item => isLegitimatevalue(item.hasDone))
          if (nextItem) return nextItem.id
        }
      }
      return null
    }
  }
  else {
    if (planitems && planitems.length > 0) {
      const nextItem = planitems.find(item => isLegitimatevalue(item.hasDone))
      if (nextItem) return nextItem.id
    }
    if (nonePlayBooks && nonePlayBooks.length > 0) {
      const nextItem = nonePlayBooks.find(item => isLegitimatevalue(item.hasDone))
      if (nextItem) return nextItem.id
    }
    return null
  }
}

export function getDisabledValue({ hasDone, rocketProjectStatus, isBind = true, isLogout = false, hasOfflinePlanWritePermission }) {
  return hasDone === false || ((!rocketProjectStatus || !isBind || isLogout || !hasOfflinePlanWritePermission) && hasDone === null)
}

export function getHasDoneValue({ hasDone, rocketProjectStatus, isBind = true, isLogout = false, hasOfflinePlanWritePermission }) {
  return rocketProjectStatus && isBind && !isLogout && hasOfflinePlanWritePermission && hasDone === null
}

// 确定音乐反馈是否未做
export function getMusicHasDone({ audioRecordPunch }) {
  if (audioRecordPunch) {
    const { punched, listenedList } = audioRecordPunch || {}
    if (punched === false) {
      return null
    }
    if (punched && listenedList.length === 0) {
      return false
    }
    return true
  }
  return null
}

export function getAssessmentStatus(status, intl) {
  switch (status) {
    case 'None':
      return <span>{intl.formatMessage({ id: 'notStart' })}</span>
    case 'Started':
      return <span>{intl.formatMessage({ id: 'ongoing' })}</span>
    case 'Finished':
      return <span>{intl.formatMessage({ id: 'done' })}</span>;
    case 'ReportedReady':
      return <span>{intl.formatMessage({ id: 'reported' })}</span>
    case 'Closed':
      return <span>{intl.formatMessage({ id: 'expired' })}</span>
    case 'hasGetReport':
      return <span>{intl.formatMessage({ id: 'reportedOther' })}</span>
    default:
      return ''
  }
}

export function getCourseStatus(course, intl) {
  let title = ''
  title = course.isDeleted ? intl.formatMessage({ id: 'Suspended' }) : ''
  if (!title) {
    switch (course.status) {
      case "NotStart":
        title = intl.formatMessage({ id: 'NotActive' })
        break
      case "Active":
        title = intl.formatMessage({ id: 'Effectiveing' })
        break
      case "Expired":
        title = intl.formatMessage({ id: 'expiredOther' })
        break
      default:
        title = ''
    }
  }
  return title
}

export function showCourseValidity(course, intl) {
  if (course.startTime && course.endTime) {
    return `${moment(course.startTime).local().format('YYYY/MM/DD')}-${moment(course.endTime).local().format('YYYY/MM/DD')}`
  }
  if (course.startTime) {
    return `${moment(course.startTime).local().format('YYYY/MM/DD')}${intl.formatMessage({ id: 'start' })}`
  }
  if (course.endTime) {
    return `${moment(course.endTime).local().format('YYYY/MM/DD')}${intl.formatMessage({ id: 'finish' })}`
  }
  return intl.formatMessage({ id: 'unlimitedTime' })
}

export function findQuestionErrorList(jsonString) {
  const myRe = new RegExp('"title":{[^}]+}|"text":{[^}]+}', 'g')
  const errList = [];

  if (myRe.test(jsonString)) {
    const matchRes = jsonString.match(myRe);
    if (matchRes && matchRes.length > 0) {
      matchRes.forEach(item => {
        const { title, text } = JSON.parse('{' + item + '}')

        if (title) {
          if (!title.hasOwnProperty('default')) {
            errList.push(item)
          }
        } else if (text) {
          if (!text.hasOwnProperty('default')) {
            errList.push(item)
          }
        }
      })
    }
  }

  return errList
}

export function showContractStatus(type, intl) {
  switch (type) {
    case "NotActive":
      return intl.formatMessage({ id: 'NotActive' });
    case "Active":
      return intl.formatMessage({ id: 'Effectiveing' });
    case "Expired":
      return intl.formatMessage({ id: 'Terminated' });
    case "Deactivate":
      return intl.formatMessage({ id: 'Suspended' })
  }
}

export async function onPreviewVideo(file) {
  const { id, url } = file
  if (!id || !url) return
  const reportUrl = `${pcUrl}/showVideo?url=${url}`
  // if (id.includes(videoSeparator)) {
  //   reportUrl = `${pcUrl}/showVideo?id=${id}`
  // }
  window.open(reportUrl, '_blank')
}


export function getMobilePrefix(intl) {
  const MobilePrefix = [
    {
      prefix: "+1",
      countryName: `${intl.formatMessage({ id: 'unitedState' })} / ${intl.formatMessage({ id: 'canada' })}`
    },
    {
      prefix: "+86",
      countryName: `${intl.formatMessage({ id: 'china' })}`
    },
    {
      prefix: "+972",
      countryName: intl.formatMessage({ id: 'israel' })
    },
    {
      prefix: "+52",
      countryName: intl.formatMessage({ id: 'mexico' })
    },
    {
      prefix: "+34",
      countryName: intl.formatMessage({ id: 'spain' })
    },
    {
      prefix: "+506",
      countryName: 'Costa Rica'
    },
    {
      prefix: "+1876",
      countryName: intl.formatMessage({ id: 'Jamaica' })
    },
    {
      prefix: "+886",
      countryName: intl.formatMessage({ id: 'taiwan' })
    },
    {
      prefix: "+852",
      countryName: intl.formatMessage({ id: 'Hong Kong' })
    },
    // {
    //   prefix:  "+1",
    //   countryName: intl.formatMessage({ id: 'canada' })
    // }
  ]

  return MobilePrefix
}

export function getMobilePrefixOtherIntl(intl) {
  const MobilePrefix = [
    {
      prefix: "+1",
      countryName: `${intl('unitedState')} / ${intl('canada')}`
    },
    {
      prefix: "+86",
      countryName: `${intl('china')}`
    },
    {
      prefix: "+972",
      countryName: intl('israel')
    },
    {
      prefix: "+52",
      countryName: intl('mexico')
    },
    {
      prefix: "+34",
      countryName: intl('spain')
    },
    {
      prefix: "+506",
      countryName: 'Costa Rica'
    },
    {
      prefix: "+1876",
      countryName: intl('Jamaica')
    },
    {
      prefix: "+886",
      countryName: intl('taiwan')
    },
    {
      prefix: "+852",
      countryName: intl('Hong Kong')
    },
  ]

  return MobilePrefix
}

// 专门为见习督导评分写的方法
export function getScore(correntRateAndResult, supervisionDetaiAnswer) {
  if (!correntRateAndResult || !supervisionDetaiAnswer) return 0
  let count = 0
  const { questionArr } = correntRateAndResult || {}
  const { answerContent } = supervisionDetaiAnswer || {}
  const answer = JSON.parse(answerContent)
  const matrixArr = questionArr.filter(item => item.type === "matrix")
  matrixArr.forEach(item => {
    const answerItem = answer[item.name]
    if (answerItem) {
      const itemCount = Object.values(answerItem).reduce((result, next) => result + (parseInt(next) >= 0 ? parseInt(next) : 0), 0)
      count += itemCount
    }
  })
  return count
}

export function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function showEndTime(guardianHasDone, time) {
  if (moment().diff(moment(time), 'years') > 1000) return ""
  if (guardianHasDone === false) {
    return `${moment(time).subtract(1, 'hours').calendar(null, { sameElse: 'MM/DD/YYYY HH:mm' })} - ${moment(time).format("HH:mm")}`
  } else {
    return `${moment(time).calendar(null, { sameElse: 'MM/DD/YYYY HH:mm' })} - ${moment(time).add(1, 'hours').format("HH:mm")}`
  }
}

export function isEqual(obj1, obj2) {

  //1.如果是比较对象===，返回true
  if (obj1 === obj2) return true;

  //2.如果比较的是两个方法，转成字符串比较
  if (typeof obj1 === "function" && typeof obj2 === "function") return obj1.toString() === obj2.toString();

  //3如果obj1和obj2都是Date实例，获取毫秒值比较
  if (obj1 instanceof Date && obj2 instanceof Date) return obj1.getTime() === obj2.getTime();

  //4如果比较是两个类型不一致,无须比较直接返回false
  if (Object.prototype.toString.call(obj1) !== Object.prototype.toString.call(obj2) || typeof obj1 !== "object") return false;

  //5.获取对象所有自身属性的属性名（包括不可枚举属性但不包括Symbol值作为名称的属性
  const obj1Props = Object.getOwnPropertyNames(obj1);
  const obj2Props = Object.getOwnPropertyNames(obj2);

  //自身属性长度相等,
  if (obj1Props.length !== obj2Props.length) return false;

  //递归调用判断每一个属性值是否相等
  return (obj1Props.every(prop => isDeepObjectEqual(obj1[prop], obj2[prop])));
}

export function showRemainingTimesMsg(intl, remainingTimes, currentUser, from = 'offlinePlan', option = 'click', goSetting = () => {}) {
  const { current, changedTenant } = currentUser || {};
  const { roles = [] } = current || {};
  const { timesSetting } = changedTenant || {};

  // 没有配置timesSetting则不显示弹窗
  if (!timesSetting) return false;

  const rolesName = roles.length > 0 && roles[0].name;

  const isFromOfflinePlan = from === 'offlinePlan'
  const showOptionRoles =
    rolesName === 'CenterAdmin' || rolesName === 'SeniorCoach' || rolesName === 'Senior Coach';

  const key = `open${Date.now()}`;

  const SettingsButton = (
    <Button
      type="link"
      onClick={(e) => {
        e.stopPropagation();
        notification.close(key);
        goSetting();
      }}
    >
      {intl.formatMessage({ id: 'Settings' })}
    </Button>
  )

  if (remainingTimes !== null && remainingTimes !== undefined) {
    if (remainingTimes > 0 && remainingTimes <= 5 && isFromOfflinePlan && option !== 'click') {
      notification.warning({
        message: showOptionRoles ? (
          <Space>
            <div>{intl.formatMessage({ id: 'The remaining sessions for this child is' }, { time: remainingTimes })}</div>
            {SettingsButton}
          </Space>
        ) : (
          <span>
            {intl.formatMessage({ id: 'The remaining sessions for this child is' }, { time: remainingTimes })} {intl.formatMessage({ id: 'Please contact the admin' })}
          </span>
        ),
        description: null,
        placement: 'top',
        duration: 3,
        key,
        style: {
          backgroundColor: '#fefbe8',
          border: '1px solid #efb041',
          borderRadius: '5px',
        },
      });
    } else if (remainingTimes <= 0) {
      const showText = intl.formatMessage({ id: isFromOfflinePlan ? 'The sessions for this child has been used up' : 'The assessments for this child has been used up' })

      notification.warning({
        message: showOptionRoles ? (
          <Space>
            <div>{showText}</div>
            {SettingsButton}
          </Space>
        ) : (
          <span>{showText} {intl.formatMessage({ id: 'Please contact the admin' })}</span>
        ),
        description: null,
        placement: 'top',
        duration: 3,
        key,
        style: {
          backgroundColor: '#fefbe8',
          border: '1px solid #efb041',
          borderRadius: '5px',
        },
      });
      return true;
    }
  } else {
    const showText = intl.formatMessage({ id: isFromOfflinePlan ? 'The amount of sessions for this child is not set' : 'The amount of this assessment is not set' })

    notification.warning({
      message: showOptionRoles ? (
        <Space>
          <div>{showText}</div>
          {SettingsButton}
        </Space>
      ) : (
        <span>{showText} {intl.formatMessage({ id: 'Please contact the admin' })}</span>
      ),
      description: null,
      placement: 'top',
      duration: 3,
      key,
      style: {
        backgroundColor: '#fefbe8',
        border: '1px solid #efb041',
        borderRadius: '5px',
      },
    });
    return true;
  }

  return false;
}

export function showMusicStatusMsg(intl, type) {
  // type: status, progress, error
  const key = `open${Date.now()}`;

  let textId = 'musicPause'
  // if (type === 'progress') {
  //   textId = 'musicBuffering'
  // } else 
  if (type === 'error') {
    textId = 'musicRequestFailed'
  } else if (type === 'networkDisconnected' || type === 'currentlyCachingAudio' || type === 'audioCachingFailed') {
    textId = type
  }
  const showText = intl.formatMessage({ id: textId });

  const messageStyle = { color: '#FF4D4F', fontSize: '20px', fontWeight: 'bold' }
  const descriptionStyle = { textAlign: 'right', marginTop: '10px', fontSize: '20px', fontWeight: 'bold', color: '#3E85FF', cursor: 'pointer' }

  if (type === 'progress') {
    // localStorage.setItem('showProgress', 'show')

    // setTimeout(async () => {
    //   const showProgress = await localStorage.getItem('showProgress')
    //   if (showProgress === 'show') {
    //     notification.error({
    //       icon: <ExclamationCircleFilled style={{ color: '#FF4D4F' }}/>,
    //       message: <span style={messageStyle}>{showText}</span>,
    //       description: <div style={descriptionStyle} onClick={() => {
    //         notification.close(key)
    //       }}>
    //         {intl.formatMessage({ id: 'gotIt' })}
    //       </div>,
    //       placement: 'bottom',
    //       duration: null,
    //       key,
    //       bottom: 170,
    //       maxCount: 1,
    //       style: {
    //         backgroundColor: '#FFF2F0',
    //         border: '1px solid #FFCCC7',
    //         borderRadius: '8px',
    //         boxShadow: '0px 2px 8px 0px rgba(36,40,51,0.08)',
    //         width: 600,
    //       },
    //     });

    //     window.getProgressTimer = setInterval(async () => {
    //       const showProgress = await localStorage.getItem('showProgress')
    //       if (showProgress === 'none') {
    //         notification.close(key)
    //         clearInterval(window.getProgressTimer);
    //       }
    //     }, 150)
    //   }
    // }, 800)

    return
  } else if (type === 'status') {
    notification.error({
      icon: <ExclamationCircleFilled style={{ color: '#FF4D4F' }}/>,
      message: <span style={messageStyle}>{showText}</span>,
      description: <div style={descriptionStyle} onClick={() => {
        getDvaApp()._store.dispatch({
          type: 'musicplayer/changeMusicStatus',
          payload: { status: true },
        })
      }}>
        {intl.formatMessage({ id: 'clickContinue' })}
      </div>,
      placement: 'bottom',
      duration: null,
      key,
      bottom: 170,
      maxCount: 1,
      style: {
        backgroundColor: '#FFF2F0',
        border: '1px solid #FFCCC7',
        borderRadius: '8px',
        boxShadow: '0px 2px 8px 0px rgba(36,40,51,0.08)',
        width: 600,
      },
    });
  } else if (type === 'networkDisconnected' || type === 'currentlyCachingAudio') {
    notification.error({
      icon: <ExclamationCircleFilled style={{ color: '#FF4D4F' }}/>,
      message: <span style={messageStyle}>{showText}</span>,
      description: <div style={descriptionStyle} onClick={() => {
        notification.close(key)
      }}>
        {intl.formatMessage({ id: 'gotIt' })}
      </div>,
      placement: 'bottom',
      duration: null,
      key,
      bottom: 170,
      maxCount: 1,
      style: {
        backgroundColor: '#FFF2F0',
        border: '1px solid #FFCCC7',
        borderRadius: '8px',
        boxShadow: '0px 2px 8px 0px rgba(36,40,51,0.08)',
        width: 600,
      },
    });
  } else {
    notification.error({
      icon: <ExclamationCircleFilled style={{ color: '#FF4D4F' }}/>,
      message: <span style={messageStyle}>{showText}</span>,
      description: <div style={descriptionStyle} onClick={() => {
        window.location.reload();
      }}>
        {intl.formatMessage({ id: 'Refresh' })}
      </div>,
      placement: 'bottom',
      duration: null,
      key,
      bottom: 170,
      maxCount: 1,
      style: {
        backgroundColor: '#FFF2F0',
        border: '1px solid #FFCCC7',
        borderRadius: '8px',
        boxShadow: '0px 2px 8px 0px rgba(36,40,51,0.08)',
        width: 600,
      },
    });
  }
}

export function showCoachTimesMsg(intl, remainingTimes, option = 'click') {
  const key = `open${Date.now()}`;

  if (remainingTimes !== null && remainingTimes !== undefined) {
    if (remainingTimes > 0 && remainingTimes <= 3 && option !== 'click') {
      notification.warning({
        message: (
          <span>
            {intl.formatMessage({ id: 'sessionsAmount' }, { time: remainingTimes })}
          </span>
        ),
        description: null,
        placement: 'top',
        duration: 3,
        key,
        style: {
          backgroundColor: '#fefbe8',
          border: '1px solid #efb041',
          borderRadius: '5px',
        },
      });
    } else if (remainingTimes <= 0) {
      notification.warning({
        message: (
          <span>{intl.formatMessage({ id: 'sessionsUsedUp' })}</span>
        ),
        description: null,
        placement: 'top',
        duration: 3,
        key,
        style: {
          backgroundColor: '#fefbe8',
          border: '1px solid #efb041',
          borderRadius: '5px',
        },
      });
      return true;
    }
  }

  return false;
}

export function isActiveCredentiales(qualificationMedals, trainerCredentialses) {
  // NotAssociated 未关联
  // NotActive 未生效
  // Active 生效中
  // Expired 已过期
  if (qualificationMedals.length > 0) {
    const findData = trainerCredentialses.find(item => {
      const { trainingModule } = item || {};
      const { qualificationMedals: coachQualificationMedals = [] } = trainingModule || {};

      return coachQualificationMedals[0]?.id === qualificationMedals[0];
    }) || {}

    if (Object.keys(findData).length > 0) {
      const { startTime, endTime } = findData || {};

      if (moment().isSameOrAfter(startTime) && moment().isSameOrBefore(endTime)) {
        return 'Active'
      } else if (moment().isBefore(startTime)) {
        return 'NotActive'
      } else if (moment().isAfter(endTime)) {
        return 'Expired'
      }
    } else {
      return 'NotAssociated';
    }
  }

  return 'NotAssociated';
}

export function getUserImg(userInfo, style) {
  const {
    name,
    userName,
    childName,
    subjectName,
    guardianName,
    picture,
    gender,
    icon,
  } = userInfo || {};

  const { url } = picture || {};

  let tempName = name || userName || childName || guardianName;

  if (subjectName && subjectName.length) {
    if (Array.isArray(subjectName)) {
      tempName = subjectName[0];
    } else {
      tempName = subjectName
    }
  }

  const showUrl = url || picture || icon;

  if (!(showUrl || tempName)) {
    return (
      <img
        src={userStaff}
        style={{
          ...style,
          borderRadius: '5px',
        }}
      />
    );
  }

  if (showUrl) {
    return (
      <img
        src={showUrl}
        style={{
          borderRadius: '8px',
          ...style,
        }}
      />
    );
  } else {
    const showName = getShowName(tempName);

    const showColor = showGenderColor(gender);

    // 计算字体大小
    const defaultHeight = parseInt(style['height']) || '48px';

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          backgroundColor: showColor,
          color: '#ffffff',
          fontWeight: 600,
          fontSize: defaultHeight * 0.34,
          textAlign: 'center',
          ...style,
        }}
      >
        {showName}
      </div>
    );
  }
}

//生成或者指定一个密钥和一个初始化向量(IV)
const key = CryptoJS.enc.Utf8.parse('cglep.ecce9093ef');
const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000');

export function encryptAES(data) {
  return CryptoJS.AES.encrypt(`${data}`, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
}

export function decryptAES(data) {
  return CryptoJS.AES.decrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
}
