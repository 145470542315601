import { AdminGetSubjectInfo, GetSubjectFromTenant } from 'services/userManager'
import { message } from 'antd'

// import * as SurveyPDF from 'survey-pdf'
import { isJson } from 'cognitiveleap-core-us/utils/helper'

export const SHOWTYPE = {
  RocketAssessment: 'rocketAssessment',
  TrialSession: 'TrialSession',
  VratTest: 'vratTest',
  Sleep: 'sleep',
  Nutrition: 'nutrition',
  Ils: 'iLs',
  Exercise: 'exercise'
}

export default {
  namespace: 'adminSubjectDetail',

  state: {
    subjectInfo: null,
    currentTab: SHOWTYPE.RocketAssessment,
    subjectId: null,
    subjectResource: []
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {

    *initPage({ payload }, { call, put, take, select }) {
      yield put({ type: 'loadPage', payload })
      yield put({ type: 'getSubjectFromTenant', payload })
    },

    *getSubjectFromTenant({ payload }, { call, put, take, select }){
      const res = yield call(GetSubjectFromTenant, { subjectId: payload.id })
      if(res.response.ok) {
        yield put({ type: 'updateState', payload: { subjectResource: res.data } })
      }
    },

    *loadPage({ payload : { id = '7b016f78-c296-4817-8835-479ce1b06a3c', tab } }, { call, put, take, select }) {
      const { currentUser } = yield select(state => state.user)
      const { adminRoles: [{name} = {}] = [] } = currentUser || {}
      const res = yield call(AdminGetSubjectInfo, id)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { subjectInfo: res.data, subjectId: id, currentTab: tab || (name === 'agent' ? SHOWTYPE.VratTest : SHOWTYPE.RocketAssessment) },
        })
        yield put({ type: 'loadList',})
      }
    },

    *changeTab({ payload }, { call, put, take, select }) {
      yield put({
        type: 'updateState',
        payload: {
          currentTab: payload
        },
      })
      yield put({ type: 'loadList', })
    },

    *loadList(_, { call, put, take, select }) {
      const { currentTab } = yield select(state => state.adminSubjectDetail)
      switch (currentTab) {
        case SHOWTYPE.RocketAssessment:
          yield put({ type: 'adminSubjectAssessment/loadList' })
          break
        case SHOWTYPE.VratTest:
          yield put({ type: 'adminSubjectVrat/loadList' })
          break
        case SHOWTYPE.Sleep:
          yield put({ type: 'adminSubjectSleep/loadList' })
          break
        case SHOWTYPE.Ils:
          yield put({ type: 'adminSubjectOfflinePlan/loadList' })
          break
        case SHOWTYPE.Exercise:
          yield put({ type: 'adminSubjectExercise/loadList', payload: { current: 1 } })
          break
        case SHOWTYPE.Nutrition:
          yield put({ type: 'adminSubjectNutrition/loadList' })
          break
        default:
          break
      }
    },



    // *downloadAssessmentPDF({ payload: id }, { call, put, take, select }) {
    //   const res = yield call(GetAssessmentDetail, id)
    //   if (res.response.ok) {
    //     const userAssessmentInfo = res.data
    //     const {
    //       questionnaireAnswer: { questionnaire, answer },
    //     } = userAssessmentInfo
    //     const questionnaireJson = isJson(questionnaire.jsonString)
    //       ? JSON.parse(questionnaire.jsonString)
    //       : null
    //     const answerJson = isJson(answer.answerContent) ? JSON.parse(answer.answerContent) : null

    //     if (questionnaireJson === null || answerJson === null) {
    //       message.error(`Error Display. UserAssessmentId: ${userAssessmentInfo.id} `)
    //     } else {
    //       const surveyPDF = new SurveyPDF.SurveyPDF(questionnaireJson)
    //       surveyPDF.data = answerJson
    //       surveyPDF.save()
    //     }
    //   }
    // },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(({ pathname, query }) => {
    //     if (pathname === '/system/manage/subjectDetail') {
    //       dispatch({ type: 'initPage', payload: query })
    //     }
    //   })
    // },
  },
}
