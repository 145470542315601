import { useIntl } from 'umi'
import {
  ProjectOutlined,
  ExperimentOutlined,
  TeamOutlined,
  BarChartOutlined,
  LineChartOutlined,
  UserOutlined,
  GlobalOutlined,
  DashboardOutlined,
  SolutionOutlined,
  BellOutlined,
  MoneyCollectOutlined,
  HomeOutlined,
  SyncOutlined,
  ReadOutlined,
  AuditOutlined,
  ClusterOutlined,
  BulbOutlined,
  LockOutlined,
  LaptopOutlined,
  StarOutlined,
  DeploymentUnitOutlined,
  RobotOutlined
} from '@ant-design/icons';
import { IntlManager } from 'utils/helper';
import { getSelectHost } from 'cognitiveleap-core-us/utils/auth'
const formatMessage = IntlManager.intl.formatMessage
const getAccountSetting = () => {
  return {
    name: formatMessage({ id: 'account' }),
    icon: <UserOutlined />,
    path: '/system/menu/account',
    userPermission: 'Menu.PersonalCenter',
    children: [
      {
        name: formatMessage({ id: 'profileSetting' }),
        path: '/system/menu/account/settings',
        userPermission: 'Menu.PersonalCenter.PersonalSettings',
      },
      {
        name: formatMessage({ id: 'Personal Configuration' }),
        path: '/system/menu/account/configuration',
        userPermission: 'Menu.PersonalCenter.PersonalCoachTrainingConfig',
      }
    ],
  }
}

const getAdminList = () => {
  return [
    {
      name: '内容管理',
      icon: <ProjectOutlined />,
      path: '/system/manage/content',
      userPermission: 'Menu.ContentManagement',
      children: [
        {
          name: '训练',
          path: '/system/manage/content/project',
          userPermission: 'Menu.ContentManagement.Training',
          children: [
            {
              name: '训练活动部件',
              path: '/system/manage/content/project/programPieces',
              userPermission: 'Menu.ContentManagement.Training.TrainingParts',
              children: [
                {
                  name: '打卡反馈属性',
                  path: '/system/manage/content/project/programPieces/feedbackProperty',
                  userPermission: 'Menu.ContentManagement.Training.TrainingParts.FeedbackProperties',
                },
                {
                  name: '训练目标',
                  path: '/system/manage/content/project/programPieces/target',
                  userPermission: 'Menu.ContentManagement.Training.TrainingParts.TrainingTargets',
                },
                {
                  name: '训练类别',
                  path: '/system/manage/content/project/programPieces/category',
                  userPermission: 'Menu.ContentManagement.Training.TrainingParts.ProgramCategory',
                },
                {
                  name: '训练器材',
                  path: '/system/manage/content/project/programPieces/material',
                  userPermission: 'Menu.ContentManagement.Training.TrainingParts.ProgramMaterial',
                },
                {
                  name: '训练音轨',
                  path: '/system/manage/content/project/programPieces/track',
                  userPermission: 'Menu.ContentManagement.Training.TrainingParts.TrainingTrack',
                },
              ],
            },
            {
              name: '训练项目',
              path: '/system/manage/content/project/trainingProgram',
              userPermission: 'Menu.ContentManagement.Training.TrainingProgram',
            },
            {
              name: 'APP训练计划',
              path: '/system/manage/content/project/trainingPlan',
              userPermission: 'Menu.ContentManagement.Training.TrainingPlanTemplate',
            },
            // {
            //   name: '训练课件',
            //   path: '/system/manage/content/project/trainingCourse',
            // },
            {
              name: '线下训练计划',
              path: '/system/manage/content/project/offlinePlan',
              userPermission: 'Menu.ContentManagement.Training.OfflineTrainingPlan',
            },
            {
              name: '班级训练计划',
              path: '/system/manage/content/project/newOfflinePlan',
              userPermission: 'Menu.ContentManagement.Training.TrainingPlanV2Management',
            },
            // {
            //   name: '训练专栏',
            //   path: '/system/manage/content/project/trainingBlock',
            // },
          ],
        },
        // {
        //   name: '首页编辑',
        //   path: '/system/manage/content/dashboard',
        // },
        // {
        //   name: '场景',
        //   path: '/system/manage/content/scenario',
        // },
        // {
        //   name: 'Q&A',
        //   path: '/system/manage/content/sos',
        // },
        {
          name: '优脑音乐模块',
          path: '/system/manage/content/brainMusic',
          userPermission: 'Menu.ContentManagement.BrainMusic',
        },
        {
          name: '提醒',
          path: '/system/manage/content/reminder',
          userPermission: 'Menu.ContentManagement.ReminderTemplate',
        },
        {
          name: '标签',
          path: '/system/manage/content/tag',
          userPermission: 'Menu.ContentManagement.Tag',
        },
        {
          name: '课程',
          path: '/system/manage/content/course',
          userPermission: 'Menu.ContentManagement.Course',
          children: [
            {
              name: '教师',
              path: '/system/manage/content/course/teacher',
              userPermission: 'Menu.ContentManagement.Course.Teacher',
            },
            {
              name: 'Banner',
              path: '/system/manage/content/course/banner',
              userPermission: 'Menu.ContentManagement.Course.Banner',
            },
            {
              name: '小节（课程单元）',
              path: '/system/manage/content/course/item',
              userPermission: 'Menu.ContentManagement.Course.Section',
            },
            {
              name: '课程（很多节）',
              path: '/system/manage/content/course/course',
              userPermission: 'Menu.ContentManagement.Course.Curriculum',
            },
            {
              name: '分类（很多课）',
              path: '/system/manage/content/course/category',
              userPermission: 'Menu.ContentManagement.Course.Classification',
            },
            {
              name: '培训课程',
              path: '/system/manage/content/course/trainingCourses',
              userPermission: 'Menu.ContentManagement.Course.TrainingCourse',
            },
            {
              name: '培训模块',
              path: '/system/manage/content/course/trainingModule',
              userPermission: 'Menu.ContentManagement.Course.TrainingModule',
            },
            {
              name: '证书勋章',
              path: '/system/manage/content/course/meritMedal',
              userPermission: 'Menu.ContentManagement.Course.Medal',
            }
          ],
        },
        // {
        //   name: '文章',
        //   path: '/system/manage/content/article',
        // },
        {
          name: '考试系统',
          path: '/system/manage/content/exam',
          userPermission: 'Menu.ContentManagement.ExaminationManage',
          children: [
            {
              name: '题库',
              path: '/system/manage/content/exam/question',
              userPermission: 'Menu.ContentManagement.ExaminationManage.QuestionBank',
            },
            {
              name: '考试',
              path: '/system/manage/content/exam/exam',
              userPermission: 'Menu.ContentManagement.ExaminationManage.Examination',
            }
          ],
        },
      ],
    },
    {
      name: 'TheraLeap管理',
      icon: <RobotOutlined />,
      path: '/system/manage/nutrition',
      userPermission: 'Menu.AssessmentManagement',
      children: [
        {
          name: 'TheraLeap管理',
          path: '/system/manage/nutrition/adhd',
          userPermission: 'Menu.AssessmentManagement.VRATEvaluation',
          children: [
            {
              name: 'Daily Topic',
              path: '/system/manage/nutrition/adhd/daily',
              userPermission: 'Menu.AssessmentManagement.VRATEvaluation',
            },
            {
              name: 'Week Theme',
              path: '/system/manage/nutrition/adhd/week',
              userPermission: 'Menu.AssessmentManagement.VRATEvaluation',
            },
            {
              name: 'Template',
              path: '/system/manage/nutrition/adhd/template',
              userPermission: 'Menu.AssessmentManagement.VRATEvaluation',
            },
            {
              name: 'Tag',
              path: '/system/manage/nutrition/adhd/tag',
              userPermission: 'Menu.AssessmentManagement.VRATEvaluation',
            },
          ]
        },
        {
          name: '营养管理',
          path: '/system/manage/nutrition/category',
          userPermission: 'Menu.AssessmentManagement.VRATEvaluation',
        },
        // {
        //   name: '食物管理',
        //   path: '/system/manage/nutrition/food',
        //   userPermission: 'Menu.AssessmentManagement.VRATEvaluation',
        // }
      ]
    },
    {
      name: '测评管理',
      icon: <ExperimentOutlined />,
      path: '/system/manage/assessment',
      userPermission: 'Menu.AssessmentManagement',
      children: [
        {
          name: '问卷测评',
          path: '/system/manage/assessment/qa',
          userPermission: 'Menu.AssessmentManagement.Questionnaire',
          children: [
            {
              name: formatMessage({ id: 'evaluationList'}),
              path: '/system/manage/assessment/qa/assessment',
              userPermission: 'Menu.AssessmentManagement.Questionnaire.EvaluationList',
            },
            {
              name: '问卷列表',
              path: '/system/manage/assessment/qa/questionnaire',
              userPermission: 'Menu.AssessmentManagement.Questionnaire.QuestionnaireList',
            },
            {
              name: '报告编辑助手',
              path: '/system/manage/assessment/qa/editReport',
              userPermission: 'Menu.AssessmentManagement.Questionnaire.ReportEdit',
            },
          ],
        },
        // {
        //   name: '发展测评',
        //   path: '/system/manage/assessment/aa',
        //   userPermission: 'Menu.AssessmentManagement.DevelopmentEvaluation',
        // },
        {
          name: 'vCAT测评',
          path: '/system/manage/assessment/vrat',
          userPermission: 'Menu.AssessmentManagement.VRATEvaluation',
        },
      ],
    },
    {
      name: formatMessage({ id: 'Operation Management' }),
      icon: <ClusterOutlined />,
      path: '/system/manage/manage',
      userPermission: 'Menu.OperationalManagement',
      children: [
        {
          name: '区域管理',
          path: '/system/manage/manage/area',
          userPermission: 'Menu.OperationalManagement.RegionalManagement',
        },
        {
          name: '企业管理',
          path: '/system/manage/manage/org',
          userPermission: 'Menu.OperationalManagement.EnterpriseManagement',
        },
        {
          name: formatMessage({ id: 'Manage Centers' }),
          path: '/system/manage/manage/center',
          userPermission: 'Menu.OperationalManagement.CenterManagement',
        },
        {
          name: '员工管理',
          path: '/system/manage/manage/group',
          userPermission: 'Menu.OperationalManagement.EmployeeManagement',
        },
        {
          name: formatMessage({ id: 'DeviceManagement' }),
          path: '/system/manage/manage/device',
          userPermission: 'Menu.OperationalManagement.DeviceManagement',
        },
        {
          name: '学校管理',
          path: '/system/manage/manage/school',
          userPermission: 'Menu.OperationalManagement.SchoolManagement',
        },
        {
          name: '督导师列表',
          path: '/system/manage/manage/supervisor',
          userPermission: 'Menu.OperationalManagement.SupervisorManagement',
        },
        {
          name: '培训申请审核',
          path: '/system/manage/manage/applyReview',
          userPermission: 'Menu.OperationalManagement.TrainingApplicationReview',
        },
        {
          name: '培训人员管理',
          path: '/system/manage/manage/trainingStaff',
          userPermission: 'Menu.OperationalManagement.TrainingPersonnelManagement',
        },
        {
          name: '建议审核',
          path: '/system/manage/manage/suggestion',
          userPermission: 'Menu.OperationalManagement.Suggestion',
        },
        {
          name: formatMessage({ id: 'trainSupervison' }),
          path: '/system/manage/manage/trainerSupervised',
          userPermission: 'Menu.OperationalManagement.TrainingSupervision'
        },
        {
          name: '考试列表',
          path: '/system/manage/manage/exam',
          userPermission: 'Menu.OperationalManagement.ExamList',
        },
        {
          name: '证书管理',
          path: '/system/manage/manage/medal',
          userPermission: 'Menu.OperationalManagement.CertificateManagement',
        },
      ],
    },
    {
      name: '权限管理',
      icon: <TeamOutlined />,
      path: '/system/manage/roles',
      userPermission: 'Menu.AuthorityManagement',
      children: [
        {
          name: '管理员列表',
          path: '/system/manage/roles/adminList',
          userPermission: 'Menu.AuthorityManagement.AdministratorList',
        },
      ],
    },
    {
      name: formatMessage({ id: 'evaluationList' }),
      icon: <BarChartOutlined />,
      path: '/system/manage/statistic',
      userPermission: 'Menu.AssessmentList',
      children: [
        {
          name: formatMessage({ id: 'Test List' }),
          path: '/system/manage/statistic/vratCaseList',
          userPermission: 'Menu.AssessmentList.VRATAssessmentList',
        },
        {
          name: formatMessage({ id: 'VRATStatistics' }),
          path: '/system/manage/statistic/vratStatistic',
          userPermission: 'Menu.AssessmentList.VRATStatistics',
        },
        {
          name: '问卷测评列表',
          path: '/system/manage/statistic/questionnaireList',
          userPermission: 'Menu.AssessmentList.QuestionnaireList',
        },
        {
          name: formatMessage({ id: 'menu.TrainingPlanList' }),
          path: '/system/manage/statistic/trialSessionList',
          userPermission: 'Menu.TrainingManagement.TrainingPlanList',
        },
      ],
    },
    {
      name: '训练管理',
      icon: <DeploymentUnitOutlined />,
      path: '/system/manage/traningManage',
      userPermission: 'Menu.TrainingManagement',
      children: [
        {
          name: '训练计划列表',
          path: '/system/manage/traningManage/trainingList',
          userPermission: 'Menu.TrainingManagement.TrainingPlanList',
        },
        {
          name: '班级列表',
          path: '/system/manage/traningManage/classList',
          userPermission: 'Menu.TrainingManagement.GroupList',
        },
        {
          name: 'FocusEDTx训练',
          path: '/system/manage/traningManage/training',
          userPermission: 'Menu.TrainingManagement.FocusEDTxList',
        },
        {
          name: formatMessage({ id: 'Core session Library' }),
          path: '/system/manage/traningManage/library',
          userPermission: 'Menu.TrainingManagement.CoreSessionLibrary',
        },
      ]
    },
    {
      name: formatMessage({ id: 'Analysis' }),
      icon: <LineChartOutlined />,
      path: '/system/manage/analysis',
      userPermission: 'Menu.Statistics',
      children: [
        {
          name: formatMessage({ id: 'childUserAnalysis' }),
          path: '/system/manage/analysis/children',
          userPermission: 'Menu.Statistics.SubjectAnalysis',
        },
        {
          name: formatMessage({ id: 'parentUserAnalysis' }),
          path: '/system/manage/analysis/guardian',
          userPermission: 'Menu.Statistics.GuardianAnalysis',
        },
        {
          name: formatMessage({ id: 'VRATAnalysis' }),
          path: '/system/manage/analysis/vrat',
          userPermission: 'Menu.Statistics.VRATAnalysis',
        },
        // {
        //   name: '优脑训练分析',
        //   path: '/system/manage/analysis/iLs',
        //   userPermission: 'Menu.Statistics.UserOfflinePlanAnalysis',
        // },
        {
          name: '优脑训练分析',
          path: '/system/manage/analysis/oneToOne',
          userPermission: 'Menu.Statistics.UserOfflinePlanAnalysis',
        },
        {
          name: '班级训练分析',
          path: '/system/manage/analysis/oneToMore',
          userPermission: 'Menu.Statistics.GroupTrainingAnalysis',
        },
        {
          name: '训练师分析',
          path: '/system/manage/analysis/trainer',
          userPermission: 'Menu.Statistics.TrainerAnalysis',
        },
        {
          name: '问卷分析',
          path: '/system/manage/analysis/question',
          userPermission: 'Menu.Statistics.UserAssessmentAnalysis',
        },
        {
          name: '课程分析',
          path: '/system/manage/analysis/userClass',
          userPermission: 'Menu.Statistics.UserCourseAnalysis',
        },
      ]
    },
    {
      name: 'HFS费用',
      icon: <MoneyCollectOutlined />,
      path: '/system/manage/hfs',
      userPermission: 'Menu.HfsFee',
      children: [
        {
          name: '订单列表',
          path: '/system/manage/hfs/order',
          userPermission: 'Menu.HfsFee.OrderList',
        }
      ]
    },
    // {
    //   name: '角色管理',
    //   icon: 'solution',
    //   path: '/system/manage/role',
    //   children: [
    //     {
    //       name: '企业角色',
    //       path: '/system/manage/role/org',
    //     },{
    //       name: 'CL角色',
    //       path: '/system/manage/role/admin',
    //     }
    //   ]
    // },
    // {
    //   name: '系统配置',
    //   icon: 'setting',
    //   path: '/system/manage/setting',
    //   children: [
    //     {
    //       name: '邮件设置',
    //       path: '/system/manage/setting/mail',
    //     }
    //   ]
    // },
    {
      name:  formatMessage({ id: 'menu.userManagement' }),
      icon: <UserOutlined />,
      path: '/system/manage/user',
      userPermission: 'Menu.UserManagement',
      children: [
        {
          name: formatMessage({ id: 'UserList' }),
          path: '/system/manage/user/user',
          userPermission: 'Menu.UserManagement.UserList',
        },
        {
          name: formatMessage({ id: 'subjectList' }),
          path: '/system/manage/user/subject',
          userPermission: 'Menu.UserManagement.SubjectList',
        },
        // {
        //   name: 'FocusEDTx注销',
        //   path: '/system/manage/user/cancellation',
        //   userPermission: 'Menu.UserManagement.SubjectList',
        // },
      ],
    },
    {
      name: '项目管理',
      icon: <GlobalOutlined />,
      path: '/system/manage/application',
      userPermission: 'Menu.ProjectManagement',
      children: [
        {
          name: 'Rocket版本',
          path: '/system/manage/application/rocketapp',
          userPermission: 'Menu.ProjectManagement.RocketVersion',
        },
        {
          name: 'B端版本',
          path: '/system/manage/application/b',
          userPermission: 'Menu.ProjectManagement.BVersion',
        }
      ],
    },
    getAccountSetting(),
    {
      name: '在线人数',
      icon: <BulbOutlined />,
      path: '/system/manage/online',
      userPermission: 'Menu.PeopleOnline',
    },
    {
      name: '修改密码',
      icon: <LockOutlined />,
      path: '/system/manage/changePassword',
      userPermission: 'Menu.ChangePassword',
    }
  ]
}

const getCenterList = () => {
  const formatMessage = IntlManager.intl.formatMessage
  return [
    {
      name: formatMessage({ id: 'menu.overview' }),
      icon: <LaptopOutlined />,
      path: '/system/center/dataPanel',
      userPermission: 'Menu.Overview',
      children: [
        {
          name: formatMessage({ id: 'desktop' }),
          path: '/system/center/dataPanel/workTable',
          userPermission: 'Menu.Overview.Workbench',
          projectPermission: ['Project.Rocket', 'Project.Vrat', 'Project.PDT', 'Project.Training'],
        },
        {
          name: formatMessage({ id: 'statisticalOverview' }),
          path: '/system/center/dataPanel/dataPanel',
          userPermission: 'Menu.Overview.StatisticalOverview',
          projectPermission: ['Project.Rocket', 'Project.Vrat'],
        },
      ]
    },
    {
      name: formatMessage({ id: 'menu.userManagement' }),
      icon: <HomeOutlined />,
      path: '/system/center/menu/user',
      userPermission: 'Menu.UserManagement',
      children: [
        {
          name: formatMessage({ id: 'subjectList' }),
          path: '/system/center/menu/user/subject',
          userPermission: 'Menu.UserManagement.SubjectList',
          projectPermission: ['Project.Rocket', 'Project.Vrat', 'Project.PDT'],
        },
        {
          name: formatMessage({ id: 'menu.guardianList' }),
          path: '/system/center/menu/user/user',
          userPermission: 'Menu.UserManagement.GuardianList',
          projectPermission: ['Project.Rocket', 'Project.Vrat', 'Project.PDT'],
        },
        // {
        //   name: '班级列表',
        //   path: '/system/center/menu/user/class',
        //   userPermission: 'Business.SubjectManagement',
        // },
      ],
    },
    {
      name: formatMessage({ id: 'evaluationList' }),
      icon: <DashboardOutlined />,
      path: '/system/center/menu/statistic',
      userPermission: 'Menu.AssessmentList',
      projectPermission: ['Project.Rocket', 'Project.Vrat'],
      children: [
        {
          name: formatMessage({ id: 'menu.VRATTestList' }),
          path: '/system/center/menu/statistic/vratList',
          userPermission: 'Menu.AssessmentList.VRATAssessmentList',
          projectPermission: ['Project.Vrat'],
        },
        {
          name: formatMessage({ id: 'questionnaireEvaluationList' }),
          path: '/system/center/menu/statistic/questionnaireList',
          userPermission: 'Menu.AssessmentList.QuestionnaireList',
          projectPermission: ['Project.Rocket'],
        },
        {
          name: formatMessage({ id: 'menu.TrainingPlanList' }),
          path: '/system/center/menu/statistic/trialSessionList',
          userPermission: 'Menu.TrainingManagement.TrainingPlanList',
          projectPermission: ['Project.Rocket'],
        },
      ],
    },
    {
      name: formatMessage({ id: 'menu.Training' }),
      icon: <DeploymentUnitOutlined />,
      path: '/system/center/menu/traningManage',
      userPermission: "Menu.TrainingManagement",
      projectPermission: ['Project.Rocket', 'Project.PDT'],
      children: [
        {
          name: formatMessage({ id: 'menu.TrainingList' }),
          path: '/system/center/menu/traningManage/trainList',
          userPermission: 'Menu.TrainingManagement.TrainingPlanList',
          projectPermission: ['Project.Rocket'],
        },
        {
          name: formatMessage({ id: 'menu.Class' }),
          path: '/system/center/menu/traningManage/classList',
          userPermission: 'Menu.TrainingManagement.GroupList',
          projectPermission: ['Project.Rocket'],
        },
        {
          name: formatMessage({ id: 'focusEDTTraining' }),
          path: '/system/center/menu/traningManage/training',
          userPermission: 'Menu.TrainingManagement.FocusEDTxList',
          projectPermission: ['Project.PDT'],
        },
        {
          name: formatMessage({ id: 'Core session Library'}),
          path: '/system/center/menu/traningManage/library',
          userPermission: 'Menu.TrainingManagement.CoreSessionLibrary',
          projectPermission: ['Project.PDT'],
        },
        // Todo
        // {
        //   name: 'FocusEDTx Cancellation',
        //   path: '/system/center/menu/traningManage/cancellation',
        //   projectPermission: ['Project.PDT'],
        // },
      ]
    },
    {
      name: formatMessage({ id: 'menu.Statistics' }),
      icon: <LineChartOutlined />,
      path: '/system/center/menu/analysis',
      userPermission: 'Menu.Statistics',
      projectPermission: ['Project.Rocket', 'Project.Vrat'],
      children: [
        {
          name: formatMessage({ id: 'childUserAnalysis' }),
          path: '/system/center/menu/analysis/children',
          userPermission: 'Menu.Statistics.SubjectAnalysis',
          projectPermission: ['Project.Rocket', 'Project.Vrat'],
        },
        {
          name: formatMessage({ id: 'parentUserAnalysis' }),
          path: '/system/center/menu/analysis/guardian',
          userPermission: 'Menu.Statistics.GuardianAnalysis',
          projectPermission: ['Project.Rocket', 'Project.Vrat'],
        },
        {
          name: formatMessage({ id: 'VRATAnalysis' }),
          path: '/system/center/menu/analysis/vrat',
          userPermission: 'Menu.Statistics.VRATAnalysis',
          projectPermission: ['Project.Vrat'],
        },
        {
          name: formatMessage({ id: 'brainTrainAnalysis' }),
          path: '/system/center/menu/analysis/iLs',
          userPermission: 'Menu.Statistics.UserOfflinePlanAnalysis',
          projectPermission: ['Project.Rocket'],
        },
        {
          name: formatMessage({ id: 'classAnalysis' }),
          path: '/system/center/menu/analysis/oneToMore',
          userPermission: 'Menu.Statistics.GroupTrainingAnalysis',
          hideInstitutionType: ['Medical_Public'],
          projectPermission: ['Project.Rocket'],
        },
        {
          name: formatMessage({ id: 'courseAnalysis' }),
          path: '/system/center/menu/analysis/userClass',
          userPermission: 'Menu.Statistics.UserCourseAnalysis',
          projectPermission: ['Project.Rocket'],
        }
      ]
    },
    {
      name: formatMessage({ id: 'HFSFees' }),
      icon: <MoneyCollectOutlined />,
      path: '/system/center/menu/hfs',
      userPermission: 'Menu.HfsFee',
      projectPermission: ['Project.Rocket'],
      children: [
        {
          name: formatMessage({ id: 'HFSFeeOverview' }),
          path: '/system/center/menu/hfs/costOverview',
          userPermission: 'Menu.HfsFee.HfsExpenseOverview'
        },
        {
          name: formatMessage({ id: 'OrderList' }),
          path: '/system/center/menu/hfs/order',
          userPermission: 'Menu.HfsFee.OrderList'
        }
      ]
    },
    // {
    //   name: formatMessage({ id: 'menu.content' }),
    //   icon: 'project',
    //   path: '/system/center/menu/content',
    //   permission: ['Project.Rocket'],
    //   children: [
    //     // {
    //     //   name: '线上训练计划',
    //     //   path: '/system/center/menu/content/trainingPlan',
    //     // },
    //     {
    //       name: formatMessage({ id: 'menu.offlineTrainingPlan' }),
    //       path: '/system/center/menu/content/offlinePlan',
    //     },
    //     // {
    //     //   name: '教程',
    //     //   path: '/system/center/content/tutorial',
    //     // },
    //   ],
    // },
    {
      name: formatMessage({ id: 'menu.operationsManagement' }),
      icon: <ClusterOutlined />,
      path: '/system/center/menu/manage',
      userPermission: 'Menu.OperationalManagement',
      projectPermission: ['Project.Rocket', 'Project.Vrat', 'Project.PDT', 'Project.Training'],
      children: [
        {
          name: formatMessage({ id: 'teamManagement' }),
          path: '/system/center/menu/manage/group',
          userPermission: 'Menu.OperationalManagement.EmployeeManagement',
          projectPermission: ['Project.Rocket', 'Project.Vrat', 'Project.PDT'],
        },
        {
          name: formatMessage({ id: 'DeviceManagement' }),
          path: '/system/center/menu/manage/device',
          userPermission: 'Menu.OperationalManagement.DeviceManagement',
          projectPermission: ['Project.Rocket', 'Project.Vrat', 'Project.PDT'],
        },
        {
          name: formatMessage({ id: 'Coach Configuration' }),
          path: '/system/center/menu/manage/coachConfiguration',
          userPermission: 'Menu.OperationalManagement.CoachUsageConfiguration',
        },
        {
          name: formatMessage({ id: 'User Configuration' }),
          path: '/system/center/menu/manage/userConfiguration',
          userPermission: 'Menu.OperationalManagement.UserUsageConfiguration',
        },
        {
          name: formatMessage({ id: 'trainApplyReview' }),
          path: '/system/center/menu/manage/applyExamine',
          userPermission: 'Menu.OperationalManagement.TrainingApplicationReview',
          projectPermission: ['Project.Training'],
        },
        {
          name: formatMessage({ id: 'trainManagement' }),
          path: '/system/center/menu/manage/trainingStaff',
          userPermission: 'Menu.OperationalManagement.TrainingPersonnelManagement',
          projectPermission: ['Project.Training'],
        },
        {
          name: formatMessage({ id: 'productList.title' }),
          path: '/system/center/menu/manage/vratProduct',
          userPermission: 'Menu.OperationalManagement.ProductList',
          projectPermission: ['Project.Vrat'],
        },
        // {
        //   name: formatMessage({ id: 'locationManage' }),
        //   path: '/system/center/menu/manage/location',
        //   userPermission: 'Menu.OperationalManagement.TestLocationManagement',
        // },
      ],
    },
    // {
    //   name: '数据分析',
    //   icon: 'statistic',
    //   path: '/system/center/statistic',
    //   children: [],
    // },
    getAccountSetting(),
    {
      icon: <AuditOutlined />,
      name: formatMessage({ id: 'trainCenter' }),
      path: '/system/center/menu/personalCenter',
      userPermission: 'Menu.TrainingCenter',
      projectPermission: ['Project.Training'],
    },
    {
      name: formatMessage({ id: 'messageCenter' }),
      icon: <BellOutlined />,
      path: '/system/center/menu/notification',
      userPermission: 'Menu.MessageCenter',
      projectPermission: ['Project.Rocket', 'Project.Vrat', 'Project.PDT', 'Project.Training'],
      children: [
        {
          name: formatMessage({ id: 'messageCenter' }),
          path: '/system/center/menu/notification/center',
        },
        {
          name: formatMessage({ id: 'Contact Us' }),
          path: '/system/center/menu/notification/contact',
        },
      ]
    },
    {
      name: formatMessage({ id: 'database' }),
      icon: <ReadOutlined />,
      path: '/system/center/menu/trainerDatabase',
      userPermission: 'Menu.Database',
      projectPermission: ['Project.Database'],
    },
  ]
}

const getEnterpriseList = () => {
  return [
    {
      name: formatMessage({ id: 'Operation Management' }),
      icon: <TeamOutlined />,
      path: '/system/enterprise/manage',
      // permission: '',
      children: [
        {
          name: formatMessage({ id: 'Manage Centers'}),
          path: '/system/enterprise/manage/center',
          children: [
            {
              name: '中心列表',
              path: '/system/enterprise/manage/center/centerList',
            },
          ],
        },
        {
          name: '团队管理',
          path: '/system/enterprise/manage/group',
        },
        // {
        //   name: '数据分析',
        //   icon: 'statistic',
        //   path: '/system/center/statistic',
        //   children: [],
        // },
      ],
    },
  ]
}

const getAreaList = () => {
  return [
    {
      name: formatMessage({ id: 'Operation Management' }),
      icon: <TeamOutlined />,
      path: '/system/area/manage',
      // permission: '',
      children: [
        {
          name: formatMessage({ id: 'Manage Centers'}),
          path: '/system/area/manage/center',
          children: [
            {
              name: '中心列表',
              path: '/system/area/manage/center/centerList',
            },
          ],
        }
      ],
    },
  ]
}

const getEmptyCenterList = () => {
  const formatMessage = IntlManager.intl.formatMessage

  return [
    {
      name: formatMessage({ id: 'account' }),
      icon: <UserOutlined />,
      path: '/system/menu/account',
      children: [
        {
          name: formatMessage({ id: 'profileSetting' }),
          path: '/system/menu/account/settings',
        }
      ],
    },
    // {
    //   name: '个人页',
    //   icon: <UserOutlined />,
    //   path: '/system/center/menu/account',
    //   children: [
    //     {
    //       name: '个人设置',
    //       path: '/system/center/menu/account/settings'
    //     },
    //   ],
    // }
  ]
}

function filterProjectMenuList(menuList, permissionList) {
  const filteredList = []
  //当menu里面的permission里面的key，至少有一个是用户的权限，则显示出来
  menuList.forEach(item => {
    const { projectPermission, children } = item
    const showMenuItem = projectPermission === undefined ||
      projectPermission.some(permissionItem => permissionList.includes(permissionItem))

    if (showMenuItem) {
      const childrenList = children !== undefined ? filterProjectMenuList(children, permissionList) : null
      const menuItem = childrenList !== null ? { ...item, children: childrenList } : item
      filteredList.push({
        ...menuItem,
      })
    }
  })
  return filteredList
}

function filterEmptyChildrenItem(menuList) {
  const filteredList = []
  menuList.forEach(item => {
    const { children } = item
    if (children === undefined || filterEmptyChildrenItem(children).length > 0)
      filteredList.push({
        ...item,
      })
  })
  return filteredList
}

function filterUserMenuList(menuList, grantedPolicies, timesSetting = true) {
  const filteredList = []
  menuList.forEach(item => {
    const { userPermission, children, path } = item
    const showMenuItem = 
      userPermission === undefined || 
      (
        grantedPolicies[userPermission] === true && 
        (path === '/system/center/menu/manage/userConfiguration' ? timesSetting : true)
      )

    if (showMenuItem) {
      const childrenList = children !== undefined ? filterUserMenuList(children, grantedPolicies, timesSetting) : null
      const menuItem = childrenList !== null ? { ...item, children: childrenList } : item
      filteredList.push({
        ...menuItem,
      })
    }
  })
  return filteredList
}

// 角色登录跳转登录页
export function getDefaultUrl(currentTenantInfo, userAllTenant, adminRoles, auth) {
  const SelectHost = getSelectHost();
  // 机构端管理端都存在进行选择登录
  if (adminRoles.length > 0 && userAllTenant.some(item => item.state === "Effective") && (SelectHost === '' || SelectHost === undefined)) {
    return '/system/choseRoles'
  }
  if (SelectHost == "Management") {
    if (currentTenantInfo === null) {
      if (adminRoles.length > 0) {
        const { name } = adminRoles[0]
        // 有的管理员没有中心页面需要单独处理
        if (adminRoles.some(item => (item.name === 'operationAdmin' || item === 'finance'))) {
          return '/system/manage'
        } else if (adminRoles.some(item => item.name === 'editor')) {
          return '/system/manage/content/tag'
        } else if (adminRoles.some(item => item.name === 'medicalSupport')) {
          return '/system/manage/statistic/vratCaseList'
        } else if (adminRoles.some(item => item.name === 'supervisor')) {
          return '/system/manage/manage/group'
        } else {
          return '/system/manage'
        }
      } else {
        return '/system/menu/account/settings'
      }
    }
  } else {
    if (currentTenantInfo) {
      const { type, projectStatus = [] } = currentTenantInfo
      if (type === 'Center') {
        // 仅Project.Database有效时跳转到个人设置页面
        const activeStatus = projectStatus.filter(item => item.status);
        if (activeStatus.length === 0 || activeStatus.every(item => item.name === 'Project.Database')) {
          return '/system/menu/account/settings'
        }
        const { grantedPolicies } = auth || {}

        if (grantedPolicies["Menu.Overview.Workbench"]) {
          return '/system/center/dataPanel/workTable'
        } else if (grantedPolicies["Menu.Overview.StatisticalOverview"]) {
          return '/system/center/dataPanel/dataPanel'
        } else if (grantedPolicies["Menu.UserManagement.SubjectList"]) {
          return '/system/center/menu/user/subject'
        } else if (grantedPolicies["Menu.UserManagement.GuardianList"]) {
          return '/system/center/menu/user/user'
        } else {
          return '/system/center'
        }
      } else if (type === 'Enterprise') {
        return '/system/enterprise'
      } else if (type === 'Area') {
        return '/system/area'
      }
    } else {
      console.log("不应该走到这里");
      return '/system/menu/account/settings'
    }
  }
}

export function getMenuList(currentTenantInfo, grantedPolicies, adminRoles) {
  if (currentTenantInfo === null) {
    if (adminRoles.length > 0) {
      // if (adminRoles.some(item => item.name === 'admin')) {
      //   // 全部的Admin功能
      //   return getAdminList()
      // } else {
      //   // 有身份的Admin
        const filteredPermissionList = filterUserMenuList(getAdminList(), grantedPolicies)
        return filterEmptyChildrenItem(filteredPermissionList)
      // }
    } else {
      return getEmptyCenterList()
    }
  } else {
    const { projectStatus, institutionType, timesSetting } = currentTenantInfo
    const userPermissionList = []
    projectStatus.forEach(item => {
      const { name, status } = item
      if (status) {
        userPermissionList.push(name)
      }
    })
    if (currentTenantInfo.type === 'Center') {
      const projectMenu = filterProjectMenuList(getCenterList(), userPermissionList)
      //return projectMenu
      const filteredPermissionList = filterUserMenuList(projectMenu, grantedPolicies, timesSetting)
      return filterEmptyChildrenItem(filteredPermissionList)
    } else if (currentTenantInfo.type === 'Enterprise') {
      const projectMenu = filterProjectMenuList(getEnterpriseList(), userPermissionList)
      //return projectMenu
      const filteredPermissionList = filterUserMenuList(projectMenu, grantedPolicies)
      return filterEmptyChildrenItem(filteredPermissionList)
    } else if (currentTenantInfo.type === 'Area') {
      const projectMenu = filterProjectMenuList(getAreaList(), userPermissionList)
      const filteredPermissionList = filterUserMenuList(projectMenu, grantedPolicies)
      return filterEmptyChildrenItem(filteredPermissionList)
    }
  }
}

// 根据pathname去查询对应的userPermission
// 查到的userPermission为undefined或者存在于grantedPolicies，则返回true，代表有权限
function checkPermissionPro(location, menuList, grantedPolicies) {
  let havePermission = false
  let canFind = false
  const { pathname } = location
  menuList.forEach(menu => {
    const { userPermission, children, path: menuPath } = menu;
    if (menuPath === pathname) {
      // 找到了对应的路由
      canFind = true;
      if (userPermission === undefined || grantedPolicies[userPermission] === true) {
        // 有权限
        havePermission = true
      }
    }

    if (children !== undefined && !havePermission && !canFind) {
      checkPermissionPro(location, children, grantedPolicies)
    }
  })

  return !canFind ? true : havePermission
}

// 核查用户身份
export function checkPermission(path, currentUser, adminRoles, grantedPolicies) {
  if (path === '/system/choseRoles') {
    return true
  } else {
    let reg = /\/[a-z]+\/[a-z]+/
    var result = path.match(reg)
    if (result !== null) {

      let currentMenuList
      if (currentUser === null) {
        currentMenuList = getAdminList()
      } else if (currentUser.type === 'Center') {
        currentMenuList = getCenterList()
      } else if (currentUser.type === 'Enterprise') {
        currentMenuList = getEnterpriseList()
      } else if (currentUser.type === 'Area') {
        currentMenuList = getAreaList()
      }

      // const currentMenuList = getMenuList(currentUser, grantedPolicies, adminRoles)
      const isVip = checkPermissionPro(window.location, currentMenuList, grantedPolicies)

      if (currentUser === null) {
        if (adminRoles.length > 0) {
          if (adminRoles.some(item => item.name === 'admin')) {
            // 仅基本判断
            return result[0] !== '/system/center' && result[0] !== '/system/enterprise' && result[0] != '/system/area'
          } else {
            return isVip
          }
        } else {
          const isInCenterPage = result[0] === '/system/center'
          if (isInCenterPage) {
            return path === '/system/menu/account/settings'
          } else {
            return result[0] !== '/system/manage' && result[0] !== '/system/enterprise' && result[0] != '/system/area'
          }
        }
      } else {
        return isVip
      }

      // if (currentUser === null) {
      //   if (adminRoles.length > 0) {
      //     if (adminRoles.some(item => item.name === 'admin')) {
      //       // 仅基本判断
      //       return result[0] !== '/system/center' && result[0] !== '/system/enterprise' && result[0] != '/system/area'
      //     } else {
      //       // return result[0] !== '/system/center' && result[0] !== '/system/enterprise' && result[0] != '/system/area'
      //     }
      //   } else {
      //     const isInCenterPage = result[0] === '/system/center'
      //     if (isInCenterPage) {
      //       return path === '/system/center/menu/account/settings'
      //     } else {
      //       return result[0] !== '/system/manage' && result[0] !== '/system/enterprise' && result[0] != '/system/area'
      //     }
      //   }
      // } else if (currentUser.type === 'Center') {
      //   return result[0] !== '/system/manage' && result[0] !== '/system/enterprise' && result[0] != '/system/area'
      // } else if (currentUser.type === 'Enterprise') {
      //   return result[0] !== '/system/center' && result[0] !== '/system/manage' && result[0] != '/system/area'
      // } else if (currentUser.type === 'Area') {
      //   return result[0] != '/system/enterprise' && result[0] !== '/system/center' && result[0] !== '/system/manage'
      // }
    }
  }
  return true
}


